import React from 'react';
import Icon1 from '../../images/svg-1.svg';
import Icon2 from '../../images/svg-5.svg';
import Icon3 from '../../images/svg-11.svg';
import Icon4 from '../../images/CSS2.png';
import Icon5 from '../../images/svg-3.svg';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesElements';

const Services = () => {
  return (
    <ServicesContainer id='services'>
              <ServicesIcon src={Icon3} />
      <ServicesH1>Athletes Can..</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Expand Network</ServicesH2>
          <ServicesP>
            Allow a community to help support athletes on their journey by leveraging the blockchain.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Build Your Brand</ServicesH2>
          <ServicesP>
          Sell NFTs directly to loyal fans. Build your athletic career with the power of the Binance chain. 
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon5} />
          {/* <ServicesIcon src={Icon4} /> */}
          <ServicesH2>Manage Sponsorships</ServicesH2>
          <ServicesP>
            Allow smart contracts to handle the on boarding of new sponsorships. Giving power back the athletes!
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
